@keyframes fade-blur {
  0% {
    opacity: 0;
    filter: blur(1rem);
  }
  30% {
    opacity: 1;
    filter: blur(0px);
  }
}

.links {
  display: flex;
  transition: all ease-out;
}

nav {
  user-select: none;
}

.linkContainer a {
  animation: fade-blur 2s ease-out;
  padding: 0 4rem;
}

.linkContainer a:hover {
  color: #000;
  background-color: rgb(252, 251, 244);
}

@media (max-width: 800px) {
  .linkContainer a {
    padding: 0 2rem;
  }

  .links {
    justify-content: center;
  }
}
@media (max-width: 650px) {
  .links {
    font-size: 12px;
  }

  .linkContainer a {
    padding: 0 1.5rem;
  }
}
@media (max-width: 370px) {
  .links {
    font-size: 12px;
  }

  .linkContainer a {
    padding: 0 1rem;
  }
}
