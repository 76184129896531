@keyframes left-fade-blur {
  0% {
    opacity: 0;
    filter: blur(1rem);
    transform: translate3d(-1rem, 0, 0);
  }
  30% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes right-fade-blur {
  0% {
    opacity: 0;
    filter: blur(1rem);
    transform: translate3d(1rem, 0, 0);
  }
  30% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }
}

.homeContainer {
  height: 80dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  row-gap: 1rem;
  max-width: 100dvw;
  padding: 7em 0;
}

.name {
  letter-spacing: 40px;
  animation: left-fade-blur 3s ease-out 0.1s backwards;
  font-size: 3rem;
  /* width: 100%; */
}

.title {
  letter-spacing: 2px;
  text-transform: uppercase;
  animation: right-fade-blur 3s ease-out 0.4s backwards;
}

@media (max-width: 1150px) {
  .name {
    letter-spacing: 25px;
  }
}

@media (max-width: 800px) {
  .name {
    letter-spacing: 15px;
  }
}
@media (max-width: 650px) {
  .name {
    font-size: 38px;
  }
}
@media (max-width: 500px) {
  .name {
    font-size: 35px;
    letter-spacing: 10px;
  }

  .title {
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .name {
    font-size: 32px;
    letter-spacing: 8px;
  }
}
@media (max-width: 350px) {
  .name {
    font-size: 30px;
    letter-spacing: 7px;
  }
}
