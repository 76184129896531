@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "./keyframes.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: baseline;
}

html {
  scroll-behavior: auto;
  height: 100%;
  max-width: 100dvw;
  cursor: default;
}

body {
  font: 15px "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 2.2;
  background: var(--background-color);
  color: var(--black);
  position: relative;
  height: 100%;
  z-index: 1;
  overflow-x: hidden;
}
.app {
  z-index: 1;
}

.appContainer {
  background-image: linear-gradient(
    to bottom,
    #09004f,
    #090559,
    #0a0a64,
    #09106f,
    #07167a,
    #041777,
    #021873,
    #001970,
    #01155d,
    #02114b,
    #050a3a,
    #070029
  );
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  letter-spacing: 0.4px;
  text-transform: lowercase;
  font-weight: 400;
  transition: 350ms ease-out;
  height: 100%;
  display: inline-block;
  color: rgb(252, 251, 244);
}

footer {
  color: rgb(252, 251, 244);
}

main {
  color: rgb(252, 251, 244);
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  padding: 5em 5em 40px 5em; /* bottom value for footer height*/
  margin: 0 auto;
  flex: 1;
  max-width: 80vw;
}

h1 {
  color: var(--bright-main);
  letter-spacing: 0.2rem;
  font-weight: 400;
  font-size: 2.5rem;
  text-transform: lowercase;
  line-height: 1.8;
  padding-top: 1rem;
}

h2 {
  color: var(--deep-gray);
  text-transform: lowercase;
  font-size: 1.7rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.7;
}

@media (max-width: 1300px) {
  main {
    max-width: 90vw;
  }
}

@media (max-width: 910px) {
  main {
    max-width: 90vw;
    padding: 5em 0 40px 0;
  }
}
