@keyframes fade-in {
  0% {
    opacity: 0;
    /* filter: blur(0.2rem); */
  }

  100% {
    opacity: 1;
    /* filter: blur(0); */
  }
}

.background {
  background-color: black;
  width: 419px;
  height: 237px;
  border-radius: 2px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  animation: fade-in 1s backwards;
}

.overlay {
  opacity: 0;
  transition: opacity 0.2s ease-out;
  width: 419px;
  height: 244px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
  /* border-radius: 2px; */
}

.background:hover .overlay {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.83);
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}

.link {
  font-size: 30px;
  color: #4359e6;
  opacity: 1;
}

.description {
  font-style: italic;
  line-height: 1.1;
  text-align: center;
}

.title {
  line-height: 1.2;
  text-transform: none;
}

@media (max-width: 650px) {
  .background:first-child {
    background-position: left;
  }

  .background {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
    border: 1px solid black;
  }

  .overlay {
    width: 200px;
    height: 200px;
  }

  .title {
    /* line-height: 1.5; */
    font-size: 1.5rem;
  }

  .link {
    font-size: 28px;
  }
}

@media (max-width: 500px) {
  .background {
    width: 150px;
    height: 150px;
  }

  .overlay {
    width: 150px;
    height: 150px;
    padding: 0.5rem;
  }

  .title {
    /* line-height: 1.1; */
    font-size: 1rem;
  }

  .description {
    font-size: 0.7rem;
    margin-top: 10px;
  }

  .link {
    font-size: 27px;
  }
}
