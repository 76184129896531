@keyframes bottom-fade-blur {
  0% {
    opacity: 0;
    filter: blur(1rem);
    transform: translate3d(0, 1rem, 0);
  }
  30% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }
}

.schoolContainer {
  /* background-color: #f1efee; */
  /* color: #000; */
  font-size: 1rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: start;
  padding: 20px;
  line-height: 1.5;
  backdrop-filter: blur(5px);
  flex: 1;
}

.degree {
  text-transform: none;
  font-size: 1.1rem;
  font-weight: 500;
  animation: bottom-fade-blur 3s ease-out 0.7s backwards;
}

.degreeItems {
  list-style: disc;
  padding-left: 1.7rem;
  animation: bottom-fade-blur 3s ease-out 0.7s backwards;
}

/* .school {
  color: #000;
}

.gpa {
  color: #000;
}

.gradDate {
  color: #000;
} */

.children {
  font-style: italic;
  /* color: #000; */
}

@media (max-width: 650px) {
  .schoolContainer {
    max-width: 400px;
    font-size: 14px;
  }
}
