@keyframes bottom-fade-blur {
  0% {
    opacity: 0;
    filter: blur(1rem);
    transform: translate3d(0, 1rem, 0);
  }
  30% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }
}
.skill {
  border-radius: 10px;
  font-size: 14px;
  padding: 0 0.3em;
  min-width: 5em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  font-weight: 400;
}

.skillsContainer {
  max-width: 350px;
}

.skillContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px;
  backdrop-filter: blur(5px);
  padding: 20px;
  animation: bottom-fade-blur 3s ease-out 0.7s backwards;
}

/* .skillContainerBox {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.skillHeading {
  animation: bottom-fade-blur 3s ease-out 0.7s backwards;
}

.language {
  background: #ffd6db;
  color: #a7060e;
}

.library {
  background: #a8e2ff;
  color: #0454cc;
}

.database {
  background: #cbbefa;
  color: #401ddd;
}

.other {
  background: #ccf3c9;
  color: #05741d;
}

.ecommerce-cms {
  background: #fdd1f6;
  color: #b10870;
}

@media (max-width: 910px) {
  .skillsContainer {
    max-width: 100%;
  }
}
@media (max-width: 650px) {
  .skillsContainer {
    max-width: 100%;
  }

  .skill {
    font-size: 13px;
  }
}
@media (max-width: 400px) {
  .skill {
    font-size: 11px;
  }
}
