@keyframes fade-in-blur {
  0% {
    opacity: 0;
    filter: blur(1rem);
  }

  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

.projectsContainer {
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;

  height: 100%;
}

.projectsContainerHeader {
  animation: fade-in-blur 0.7s backwards;
}

.pageContainer {
  display: block;
}

@media (max-width: 650px) {
  .projectsContainer {
    gap: 1rem;
  }
}
